<template>
  <SkeletonLoader
    v-if="loading"
    type="headline"
    :class="[inline ? 'inline-block' : 'flex']"
  />
  <component
    :is="tag"
    v-else
    data-testid="headline"
    class="items-center gap-2"
    :class="[classes]"
    :style="style"
  >
    <slot />
    <slot :badge="badge" name="badge">
      <HeadlineBadge v-if="badge" :badge="badge" />
    </slot>
  </component>
</template>

<script setup lang="ts">
import type { HeadlineLook } from '~/constants/ui'
import { HeadlineSize, HeadlineTag, FontWeightType } from '~/constants/ui'
import type { SbHeadline } from '~/storyblok/types/storyblok'
import { getHeadlineClassNames } from '~/storyblok/utils/cssClassesHelpers'

type Props = {
  loading?: boolean
  isUppercase?: boolean
  hidden?: boolean
  isBold?: boolean
  badge?: number | string
  size?: HeadlineSize
  tag?: HeadlineTag
  weight?: FontWeightType
  inline?: boolean
  look?: HeadlineLook
  color?: SbHeadline['color'] | string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  isUppercase: false,
  hidden: false,
  badge: 0,
  size: undefined,
  tag: HeadlineTag.H1,
  weight: FontWeightType.SEMIBOLD,
  isBold: false,
  inline: false,
  look: undefined,
  color: undefined,
})

const MAPPED_TAGS = ['div', 'span', 'p']

const sizeClass = computed(() => {
  const size = Object.values(HeadlineSize).find((it) => it === props.size)
  return `text-${size}`
})

const style = computed(() => {
  const color = parseColor(props.color)
  return color ? { color } : undefined
})

const classes = computed(() => {
  const look =
    !props.look && MAPPED_TAGS.includes(props.tag) ? props.tag : props.look

  return [
    sizeClass.value,
    props.inline ? 'inline' : 'flex',
    {
      uppercase: props.isUppercase,
      'visually-hidden': props.hidden,
      '!font-bold': props.isBold,
    },
    getHeadlineClassNames(toHeadlineLook(look), props.weight),
  ]
})

defineOptions({ name: 'FimHeadline' })
</script>
