<template>
  <component
    v-bind="{ ...$props, ...$attrs, ...(isExternalLink ? { href: to } : {}) }"
    :is="tag"
    :id="id"
    :to="!isExternalLink ? to : null"
    :active-class="activeClasses.join(' ')"
    :aria-label="ariaLabel"
    :exact-active-class="exactActiveClasses.join(' ')"
    :rel="rel"
    :target="target"
    class="whitespace-no-wrap relative inline-flex items-center gap-2 leading-5 tracking-wide transition duration-200 ease-linear"
    :class="{
      'text-gray-700': type === 'whisper',
      'type-quiet underline': type === 'quiet',
      uppercase: type === 'loud',
      'type-underline': type === 'underline',
      'type-underline-alternative': type === 'underline-alternative',
      'font-semibold uppercase': type === 'extra-loud',
      'hover:underline hover:decoration-black': hover === 'underline',
      'type-quiet-hover-underline': type === 'quiet' && hover === 'underline',
      'hover:text-shadow': hover === 'bold',
      'text-sm': textSize === 'sm',
      'text-xs': textSize === 'xs',
      'text-md text-base': textSize === 'md',
      'text-lg': textSize === 'lg',
      'text-xl': textSize === 'xl',
      'border border-primary bg-primary !text-white hover:bg-primary-400':
        isPrimary,
      'border bg-secondary text-primary hover:bg-secondary-600 hover:text-primary-400':
        isSecondary,
      textColorClasses,
    }"
  >
    <slot name="icon" />
    <span class="content pointer-events-none" :class="contentClass">
      <slot />
    </span>
    <slot name="iconAfter" />
    <slot v-if="badge !== undefined && badge > 0" name="badge" :badge="badge">
      <transition
        enter-class="opacity-0"
        enter-active-class="transition-all duration-200 ease-linear"
        enter-to-class="opacity-100"
        leave-active-class="transition-all duration-200 ease-linear"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <LinkBadge
          rounded
          :size="badgeSize"
          :class="{
            'absolute -right-2 -top-2 ': badgePosition === 'top',
            'absolute -right-2 -top-2.5 ': badgePosition === 'top-2.5',
            'absolute -right-4 -top-2':
              badgePosition === 'top' && badgeSize === 2,
            'absolute -right-4 -top-2.5':
              badgePosition === 'top-2.5' && badgeSize === 2,
            'wishlist-badge-rp absolute -top-2.5': badgePosition === 'wishlist',
            'absolute right-0 top-2': badgePosition === 'ropo',
            'border-none bg-transparent text-sm text-shade-500':
              badgePosition === 'account',
          }"
        >
          {{ badge }}
        </LinkBadge>
      </transition>
    </slot>
  </component>
</template>

<script setup lang="ts">
export type LinkTarget = '_blank' | '_self' | '_top' | '_parent' | undefined

const props = defineProps({
  to: {
    type: [String, Object] as PropType<string | object>,
    required: true,
  },
  id: {
    type: String as PropType<string>,
    default: undefined,
  },
  badge: {
    type: Number as PropType<number>,
    default: undefined,
  },
  badgeSize: {
    type: Number as PropType<1 | 2 | 3>,
    default: 1,
  },
  badgePosition: {
    type: String as PropType<string>,
    default: 'right',
    validator: (val: string) =>
      ['right', 'top', 'top-2.5', 'wishlist', 'ropo', 'account'].includes(val),
  },
  onlyExactActive: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  exactActiveColor: {
    type: String as PropType<string>,
    validator: (color: string) => ['white', 'black', 'inherit'].includes(color),
    default: () => 'black',
  },
  activeClass: {
    type: String as PropType<string>,
    default: '',
  },
  boldOnActive: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  hover: {
    type: String as PropType<string>,
    default: 'bold',
    validator: (val: string) => ['bold', 'underline'].includes(val),
  },
  target: {
    type: String as PropType<LinkTarget>,
    required: false,
    default: '_self',
  },
  type: {
    type: String,
    default: 'default',
    validator: (val: string) =>
      [
        'default',
        'loud',
        'extra-loud',
        'whisper',
        'quiet',
        'underline',
        'underline-alternative',
      ].includes(val),
  },
  textSize: {
    type: String as PropType<string>,
    default: 'xs',
    validator: (size: string) =>
      ['inherit', 'sm', 'xs', 'md', 'lg', 'xl'].includes(size),
  },
  variant: {
    type: String,
    default: 'link',
    validator: (val: string) => ['primary', 'secondary', 'link'].includes(val),
  },
  ariaLabel: {
    type: String as PropType<string>,
    default: undefined,
  },
  contentClass: {
    type: String as PropType<string>,
    default: undefined,
  },
})

const currentShop = useCurrentShop()
const isPrimary = computed(() => props.variant === 'primary')
const isSecondary = computed(() => props.variant === 'secondary')
const isLink = computed(() => props.variant === 'link')

const isExternalLink = computed(
  () =>
    typeof props.to === 'string' &&
    /:\/\//.test(props.to) &&
    currentShop.value.domain &&
    !props.to.includes(currentShop.value.domain),
)

const exactActiveClasses = computed(() => {
  const classes = []

  if (props.boldOnActive) {
    classes.push('font-semibold')
  }

  classes.push(`text-${props.exactActiveColor}`)
  classes.push(props.activeClass)

  return classes
})

const tag = computed(() =>
  props.to === ''
    ? 'button'
    : isExternalLink.value
      ? 'a'
      : resolveComponent('nuxt-link'),
)

const rel = computed(() => (isExternalLink.value ? 'noreferrer' : ''))

const textColorClasses = computed(() => ({
  'text-white': isPrimary.value,
  'text-primary-100': isSecondary.value,
  'text-primary': isLink.value,
}))

const activeClasses = computed(() => {
  if (props.onlyExactActive) {
    return []
  }

  return exactActiveClasses.value
})
</script>

<style lang="scss">
.type-quiet {
  // tailwind 2 does not support decoration-color
  text-decoration: underline #dddddd; // gray-400
}

.type-quiet-hover-underline:hover {
  text-decoration: underline black;
}

.type-underline-alternative .content {
  border-bottom: 1px solid #121212;
  padding-bottom: 1px;
}

.type-underline .content {
  border-bottom: 1px solid #121212;
  padding-bottom: 1px;
}

.type-underline-alternative,
.type-underline {
  &:hover {
    // color comes from the FIM page
    .content {
      border-color: #3e3e3c;
      color: #3e3e3c;
    }

    svg {
      color: #3e3e3c;
    }
  }
}

.wishlist-badge-rp {
  right: -0.1rem;
}
</style>
